'use client'
import { ShopifyBundle } from "lib/shopify/types";
import { useState } from "react";
import { BundleAddToCartItem } from "./bundle-add-to-cart-item";

interface AddToCartBundleProps {
    title: string;
    children: ShopifyBundle[];
}

export function BundleAddToCart({ bundle, productDiscounts }: { bundle: AddToCartBundleProps, productDiscounts: any }) {
    const [selectedBundle, setSelectedBundle] = useState<ShopifyBundle>(bundle.children[0]!)
    const selectBundle = (bundle:ShopifyBundle)=>{
        setSelectedBundle(bundle)
    }
    return (
        <div className="w-full flex flex-col">
            <div className="flex flex-col items-center justify-center">
                <BundleAddToCartItem bundle={selectedBundle} productDiscounts={productDiscounts} availableBundles={bundle.children} selectedBundle={selectedBundle} selectBundle={selectBundle}/>
            </div>
        </div>
    )
}