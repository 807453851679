'use client'
import { addToCart } from "app/actions/cart.actions";
import { createCartAndSetCookie } from "components/cart/actions";
import { useCart } from "components/cart/cart-context";
import { Spinner } from "flowbite-react";
import { useRouter } from "next/navigation";
import { startTransition, useEffect, useState } from "react";
import { cn } from "utils/cn";
import { nameToSlug } from "utils/slug-name";

export function AddBYOBundle({handle, isLink = false, classNames, redirect, addNewItems = false}: {handle: string, isLink?: boolean, classNames?: string, redirect: string, addNewItems?: boolean}) {    
  const router = useRouter()
  const {cart} = useCart()
  const [message, setMessage] = useState('')
  const [loading, setLoading] = useState(false)
  
  useEffect(() => {
    if (!cart) {
      createCartAndSetCookie();
    }
  }, [cart]);

  const formatHandle = isLink ? nameToSlug(handle) : handle
  
  const addToCartTransition = async()=>{
    setLoading(true)
    startTransition(async() => {
      const {message : messageResponse} = await addToCart(formatHandle, addNewItems)
      setLoading(false)
      setMessage(messageResponse)
    })
  } 
  useEffect(() => {
      if (message === 'success') {
        router.push(redirect)
        router.refresh()
      }
  } ,[message])
  
  const buttonClasses = cn('px-6 flex items-center flex-nowrap bg-fp-green hover:bg-white hover:border-fp-green border-2 min-w-24 min-h-10 rounded-md text-white hover:text-fp-green mt-4 w-full justify-center p-4 font-bold', classNames)
  const linkClasses = cn('hover:text-fp-green', classNames)
  return (
       <>
          <button onClick={addToCartTransition}  className={isLink ? linkClasses: buttonClasses}>
            {loading && !isLink? <Spinner aria-label="Large spinner" size="lg" />: isLink ? handle: 'Add to cart'}
          </button>
        </>
    );
  }