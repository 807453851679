import { ShopifyBundle } from "lib/shopify/types";
import Image from "next/image";
import Link from "next/link";
import { cn } from "utils/cn";
import { CurrencyType, mapCurrencyToSign } from "utils/mapCurrencyToSign";
import { ShopifyRichText } from "utils/rich-text-converter";
import { AddToCartBundle } from "./add-to-cart-button";

export function BundleAddToCartItem({ bundle, productDiscounts, availableBundles, selectBundle, selectedBundle  }: { bundle: ShopifyBundle, productDiscounts: any, availableBundles: ShopifyBundle[], selectBundle: (bundle: ShopifyBundle)=>void , selectedBundle: ShopifyBundle}) {
    const {id, title: fullTitle, handle, bundleComponents, metafields, featuredImage, priceRangeV2, compareAtPriceRange} = bundle
    const title = fullTitle.includes('System') ? fullTitle.split('System')[0] + 'System' : fullTitle;
    const summary = metafields.nodes.find(metafield => metafield.key === 'summary')?.value ?? ''
    const currencyCode = mapCurrencyToSign(priceRangeV2.minVariantPrice.currencyCode as CurrencyType) ?? ''
    const price = Number(priceRangeV2.minVariantPrice.amount) ?? ''
    const findProductDiscount = productDiscounts.find((discount: { customerGets: { items: { products: { edges: any[]; }; }; }; }) => discount.customerGets?.items?.products?.edges?.find((product: { node: { handle: string; }; }) => product.node.handle === handle))
    const discount = findProductDiscount?.customerGets?.value.percentage ?? 0
    const subtotal = price * (1 - discount)
    const compareAtPrice = +compareAtPriceRange?.maxVariantCompareAtPrice?.amount
    const calculateSaving = (1 - (subtotal / compareAtPrice)) * 100
    const bundleCameras = getAvailableCameras(availableBundles)
    
    return (
        <div className="flex flex-col md:flex-row w-full border-[1px] border-[#d1d0cb] mb-12">
            <div className="w-full order-2 px-8 md:order-1 lg:w-[43%]">
                <h2 className="text-[28px] pt-8 text-fp-dark-gray font-bold uppercase">
                    {title}
                </h2>
                <div className="mt-4">
                    <div className="text-fp-darker-gray font-light text-[15px]"><ShopifyRichText {...JSON.parse(summary)} options={[]}/></div>
                </div>
                <div className="mt-4 columns-2 font-light text-[15px]">
                    {bundleComponents.nodes.sort((a,b)=> {
                        if (a.componentProduct.handle === 'home-defense-kit') return 1;
                        if (b.componentProduct.handle === 'home-defense-kit') return -1;
                        
                        const productOrder = [
                            'display-hub',
                            'hubkeypad',
                            'door-window-sensor',
                            'motion-sensor',
                            'smoke-and-heat-sensor',
                            'indoor-camera',
                            'outdoor-security-cameras'
                        ];
                        
                        const indexA = productOrder.indexOf(a.componentProduct.handle);
                        const indexB = productOrder.indexOf(b.componentProduct.handle);
                        
                        if (indexA === -1 && indexB === -1) return 0;
                        
                        if (indexA === -1) return 1;
                        if (indexB === -1) return -1;
                        
                        return indexA - indexB;
                    }).map((component) => {
                        const componenteId = component.componentProduct.id
                        const componentHandle= component.componentProduct.handle
                        const componentQuantity = component.quantity
                        const componentTitle = component.componentProduct.title
                        const isDefenceKit = componentHandle === 'home-defense-kit'
                        return <div className="" key={componenteId}>
                            <Link className="hover:text-fp-green"  scroll={false} href={`/products-modal/${componentHandle}`}><span className="font-bold">{componentQuantity}x </span><span className={cn('', {'underline': isDefenceKit})}>{componentTitle}</span>{isDefenceKit && <span> (3 items)</span>}</Link>
                        </div>  
                    })}
                </div>
                <div className="flex flex-col justify-center items-center text-sm mt-4">
                    <div className="font-bold">Choose your camera</div>
                    <div className="w-full text-center">
                        {bundleCameras.map(camera => {
                            const displayCameras = camera.cameras.map(camera => `${camera.quantity>1 ? camera.quantity : ''} ${camera.item.title}`).join(' + ')
                            return (
                                <span className="m-1" key={`${id}-${camera.bundle.id}`}>
                                    <button className={cn("border-[#008eaa] border-[1px] p-1 rounded-[5px] m-1", {"bg-[#008eaa] text-white": selectedBundle.handle === camera.bundle.handle})} onClick={()=> selectBundle(camera.bundle)}>
                                        {displayCameras}
                                    </button>
                                </span>
                            )
                        })}
                    </div>
                </div>
                <div className="mt-4 text-2xl flex items-center justify-center">
                    <span className="text-[#008eaa]">Save {calculateSaving.toFixed(0)}%</span>
                    <span className="line-through ml-4">{currencyCode}{compareAtPrice}</span>
                </div>
                <div className="mt-4 font-bold flex items-center justify-center text-3xl">
                    <span>{currencyCode}{Math.floor(subtotal).toFixed(2)}</span>
                </div>
                <AddToCartBundle handle={selectedBundle.handle}/>
                <Link href={`/shop/${handle}`} className="mt-2 text-center block text-fp-green font-bold underline hover:text-fp-dark-gray">SEE PACKAGE DETAILS</Link>
            </div>
            <div className="w-full order-1 md:order-2 lg:w-[57%]">
                <Image src={featuredImage.url} alt={featuredImage.altText || `${title}`} width={800} height={800} className="w-full h-auto"/>
            </div>
        </div>
    )
}

export const getAvailableCameras = (availableBundles :ShopifyBundle[])=>{
    const allProductBundles = availableBundles.map(bundle => bundle.bundleComponents.nodes.map((node) => {return {componentProduct: node.componentProduct, bundle, quantity: node.quantity}})).flat()
    const availableCameras = allProductBundles.filter(product => product.componentProduct.title.toLowerCase().includes('camera'))
    const camerasDict: { [key: string]: any[] } = {}
    for (const bundle of availableBundles) {
        camerasDict[bundle.handle] = []
    }
    for (const camera of availableCameras) {
        if(camerasDict[camera.bundle.handle]){
            camerasDict[camera.bundle.handle]?.push(camera)
        }else{
            camerasDict[camera.bundle.handle] = [camera]
        }
    }
    for (const bundle of availableBundles) {
        if(!camerasDict[bundle.handle]?.length){
            camerasDict[bundle.handle]?.push({componentProduct: {title: 'No Camera', handle: 'no-camera'}, bundle, quantity: 0})
        }
    }
    return Object.entries(camerasDict).map(([key, value]) => {
        return {
            bundle: value[0].bundle,
            cameras: value.map(camera => {return {item: camera.componentProduct, quantity: camera.quantity} })
        }
    })   
}